import React, { Suspense, useEffect, useState } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { initializeFirestoreListeners } from "./config/firebase";
import { routes } from "./config/routes";
import { tooltipConfig } from "./config/tooltipConfig";
import { useSelector } from "./utils";

import PrivateRoute from "./components/PrivateRoute";
import Navbar from "./components/Navbar";

import AppNotifications from "./views/AppNotifications";

import { _user } from "./views/App/User/selectors";

export const LoadingView = () => (
  <div className="container-fluid my-5 py-5 ">
    <div className="row d-flex justify-content-center align-items-center">
      <div className="spinner-border text-primary  m-0" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
);

export const DelayedLoadingView = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), 300);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {show && (
        <div className="container-fluid my-5 py-5 ">
          <div className="row my-5 py-5 d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary  m-0" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function App() {
  const user = useSelector(_user);

  useEffect(() => {
    let userListener: any;
    let notificationsListener: any;

    if (user?.uid) {
      const listeners = initializeFirestoreListeners({ uid: user.uid });
      userListener = listeners.userListener;
    }

    return () => {
      if (userListener && typeof userListener === "function") {
        userListener();
      }

      if (notificationsListener && typeof notificationsListener === "function") {
        notificationsListener();
      }
    };
  }, [user?.uid]);

  return (
    <Router>
      <AppNotifications />
      <Navbar />
      <div className="container-fluid app-border">
        <Suspense fallback={<DelayedLoadingView />}>
          <Switch>
            {routes.map((route, index) =>
              route.private ? (
                <PrivateRoute
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                />
              ) : (
                <Route
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                />
              )
            )}
          </Switch>
        </Suspense>
        <ReactTooltip {...tooltipConfig} />
      </div>
    </Router>
  );
}

export default App;
