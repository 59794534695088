import { createSlice } from "@reduxjs/toolkit";
import { Content, ContentSource } from "types";
import { addContent, deleteContent, getContent } from "./api";

interface UserState {
  current: number;
  existing: Content[];
  loading: boolean;
  currentViewType: ContentSource | "all";
}

const initialState: UserState = {
  current: 0,
  existing: [],
  loading: false,
  currentViewType: "all",
};

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    setExisting: (state, action) => {
      state.existing = action.payload;
    },
    setCurrentViewType: (state, action) => {
      state.currentViewType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getContent.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getContent.fulfilled, (state, action) => {
      state.existing = action.payload;
      state.loading = false;
    });

    builder.addCase(addContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addContent.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addContent.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteContent.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteContent.fulfilled, (state, action) => {
      state.loading = false;
    });
    // builder.addCase(getUser.pending, (state, action) => {
    //   state.loading = true;
    // });
    // builder.addCase(getUser.fulfilled, (state, action) => {
    //   state.user = { ...state.user, ...action.payload };
    // });
    // builder.addCase(getUser.rejected, (state, action) => {
    //   state.loading = false;
    // });
    // builder.addCase(signUp.pending, (state, action) => {
    //   state.loading = true;
    // });
    // builder.addCase(signUp.fulfilled, (state) => {
    //   state.loading = false;
    // });
    // builder.addCase(signUp.rejected, (state, action) => {
    //   state.error = action.error;
    //   state.loading = false;
    // });
    // builder.addCase(logout.pending, (state, action) => {
    //   // state.loading = true;
    // });
    // builder.addCase(logout.fulfilled, (state, action) => {
    //   state = initialState;
    //   // state.loading = false;
    // });
  },
});

export const { setCurrent, setExisting, setCurrentViewType } = contentSlice.actions;
export default contentSlice.reducer;
