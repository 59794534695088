import React, { FC } from "react";
import { Link } from "react-router-dom";
// import Logo from "../../assets/images/Fanbelt@40.png";
import { useSelector } from "../../utils";
import { _user } from "../../views/App/User/selectors";
import AvatarDropdown from "../AvatarDropdown";
import { routes } from "../../config/routes";

export const Navbar: FC = () => {
  const user = useSelector(_user);

  if (!user) {
    return null;
  }
  return (
    <nav className="d-md-none navbar bg-primary-striped text-muted border-primary navbar-expand-lg border-bottom border-primary p-0">
      <div className="container-fluid">
        <div className="col">
          <div className="row">
            <div className="col-2 d-flex align-items-center">
              <Link to="/">{/* <img src={Logo} alt="fanbelt logo" className="m-2" /> */}</Link>
            </div>
            <div className="col-10  d-flex justify-content-end align-items-center">
              <AvatarDropdown items={routes} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
