import { createSlice } from "@reduxjs/toolkit";
import { Layout } from "types";
import { addLayout, deleteLayout, getLayouts, updateLayout } from "./api";

interface LayoutState {
  current: number;
  existing: Layout[];
  loading: boolean;
}

const initialState: LayoutState = {
  current: 0,
  existing: [],
  loading: false,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    setExisting: (state, action) => {
      state.existing = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLayouts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLayouts.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getLayouts.fulfilled, (state, action) => {
      state.existing = action.payload;
      state.loading = false;
    });

    builder.addCase(updateLayout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateLayout.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateLayout.fulfilled, (state, action) => {
      const found = state.existing.findIndex((f) => f.id === action.payload.id);
      if (found) {
        state.existing[found] = { ...state.existing[found], ...action.payload.layout };
      }
      state.loading = false;
    });

    builder.addCase(addLayout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addLayout.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addLayout.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteLayout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteLayout.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteLayout.fulfilled, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setCurrent, setExisting } = layoutSlice.actions;
export default layoutSlice.reducer;
