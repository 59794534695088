import { createAsyncThunk } from "@reduxjs/toolkit";

import { Layout } from "types";

// import firebase from "firebase/app";
// import "firebase/firestore";

import {
  getFirestore,
  doc,
  collection,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
} from "firebase/firestore";

export const getLayouts = createAsyncThunk(
  "layouts/api/getLayouts",
  async ({ uid }: { uid: string }) => {
    const db = getFirestore();
    const content = await getDocs(collection(db, "users", uid, "layouts"));

    return content.docs.map((doc) => doc.data() as Layout);
  }
);

export const addLayout = createAsyncThunk(
  "layout/api/addLayout",
  async ({ uid, layout }: { uid: string; layout: Layout }) => {
    // const ref = await firebase.firestore().collection("users").doc(uid).collection("layouts").doc();

    const db = getFirestore();
    const ref = doc(collection(db, "users", uid, "layouts"));

    const obj = {
      ...layout,
      id: ref.id,
    };

    await setDoc(ref, obj);
    return obj;
  }
);

export const updateLayout = createAsyncThunk(
  "layout/api/updateLayout",
  async ({ uid, layoutId, layout }: { uid: string; layoutId: string; layout: Partial<Layout> }) => {
    const db = getFirestore();
    const ref = doc(db, "users", uid, "layouts", layoutId);
    await updateDoc(ref, { ...layout });

    return { layout, id: layoutId };
  }
);

export const deleteLayout = createAsyncThunk(
  "layout/api/deleteLayout",
  async ({ uid, layoutId }: { uid: string; layoutId: string }) => {
    const db = getFirestore();
    const ref = doc(db, "users", uid, "layouts", layoutId);
    await deleteDoc(ref);

    return layoutId;
  }
);
