const appConfigProd = require("./appConfig.prod.json");
const appConfigDev = require("./appConfig.dev.json");
const appConfigStaging = require("./appConfig.staging.json");

const firebaseConfigDev = require("./firebaseConfig.dev.json");
const firebaseConfigProd = require("./firebaseConfig.prod.json");

const configJson =
  process.env.REACT_APP_FANBELT_ENV === "production"
    ? appConfigProd
    : process.env.REACT_APP_FANBELT_ENV === "staging"
    ? appConfigStaging
    : appConfigDev;
export const config = configJson;

const firebaseConfigJson =
  process.env.REACT_APP_FANBELT_ENV !== "staging" ? firebaseConfigProd : firebaseConfigDev;

export const firebaseConfig = firebaseConfigJson;
