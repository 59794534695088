import React, { FC, useState, useEffect, useRef } from "react";
import { Menu } from "react-feather";
import classnames from "classnames";
import { useDispatch } from "../../utils";
import { logout } from "../../views/App/User/api";

import { LogOut } from "react-feather";
import { AppRouteProps } from "../../config/routes";
import { Link } from "react-router-dom";

interface Props {
  label?: string | React.ReactElement;
  photoURL?: string;
  items?: AppRouteProps[];
}

export const AvatarDropdown: FC<Props> = ({ items, label, photoURL }) => {
  const [open, setOpen] = useState<boolean>(false);
  const wrapperRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("click", documentClickHandler);

    return () => {
      document.removeEventListener("click", documentClickHandler);
    };
  }, []);

  const documentClickHandler = (event: MouseEvent) => {
    const currentRef = wrapperRef.current as any;
    const isClickingOutside = currentRef && !currentRef.contains(event.target);
    if (isClickingOutside) {
      setOpen(false);
    }
  };

  const dropdownClasses = classnames(
    "dropdown-menu dropdown-menu-end border-primary shadow rounded",
    {
      show: open,
    }
  );

  return (
    <div className="dropdown" ref={wrapperRef}>
      <button
        className="btn btn-link"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Menu />
      </button>

      <ul className={dropdownClasses} aria-labelledby="dropdownMenuButton1">
        {(items || []).map((route, index) => (
          <li key={index}>
            <Link
              className="dropdown-item d-flex justify-content-between align-items-center"
              to={route.url}
            >
              <span className="me-5">{route.label}</span> {route.icon}
            </Link>
          </li>
        ))}
        <hr />
        <li>
          <button
            onClick={() => {
              dispatch(logout());
            }}
            className="btn w-100 text-start btn-link dropdown-item d-flex justify-content-between align-items-center"
          >
            Sign Out <LogOut size={18} />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default AvatarDropdown;
