import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  collection,
  onSnapshot,
  query as fsQuery,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { initializeAnalytics } from "firebase/analytics";

// import "firebase/firestore";
// import "firebase/auth";
// import "firebase/analytics";

import { setUser, clearUser, setLoading } from "../views/App/User/reducer";

import { store } from "../store";
import { User } from "types";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
// import { apiRequest } from "../utils/api";

const { config, firebaseConfig } = require("./index");

export const initFirebase = async () => {
  //@ts-ignore
  window.FANBELT_ENV = config.app.env_name;
  console.log("Initializing with config: ", config);

  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  initializeAnalytics(app);
  auth.onAuthStateChanged(async (user) => {
    if (user && user?.uid) {
      try {
        // const fanbeltAdmin = await apiRequest<boolean>({
        //   functionName: "fanbelt-handleVerifyIsAppAdmin",
        // });
        store.dispatch(setUser({ uid: user.uid, fanbeltAdmin: true } as User));
      } catch (error) {
        console.log(error);
      } finally {
        // store.dispatch(setUser({ uid: user.uid } as User));
        store.dispatch(setLoading(false));
      }
    } else {
      store.dispatch(setLoading(false));
      store.dispatch(clearUser());
    }
  });
  return app;
};

export const setFirebaseListener = ({
  key,
  path,
  action,
  query,
}: {
  key: string;
  path: string;
  action: ActionCreatorWithPayload<any>;
  query?: string[][];
}) => {
  const db = getFirestore();
  const split = path.split("/");

  if (split.length % 2 === 0) {
    const q = fsQuery(collection(db, path), orderBy("createdOn", "desc"));
    return onSnapshot(q, async (snap: any) => {
      const data = await snap.data();
      store.dispatch(action(data));
    });
  } else {
    // const q = fsQuery(doc(db, path), orderBy("createdOn", "desc"));
    const q = fsQuery(collection(db, path), orderBy("createdOn", "desc"));
    return onSnapshot(q, async (snap: any) => {
      const data = await (
        await getDocs(q)
      ).docs.map((doc) => {
        const docData = doc.data();
        return docData;
      });
      store.dispatch(action(data));
    });

    // listener = await getDocs(q);
    // const data = listener.docs.map((doc: any) => doc.data());
    // store.dispatch(action(data));
    // listener.forEach(async (doc) => {
    //   const data = await doc.data();
    //   store.dispatch(action(data));
    // });

    // listener = onSnapshot(q, async (snap: any) => {
    //   const data = await snap.docs.map((doc: any) => doc.data());
    //   store.dispatch(action(data));
    // });
  }

  // let listener = path.split(".").reduce((acc, curr, index) => {}, {});
  // Dynamic sub/unsub of listeners
  // let listener = path.split(".").reduce((acc: any, curr: string, index: number) => {
  //   if (index % 2 === 0) {
  //     return acc.collection(curr);
  //   } else {
  //     return acc.doc(curr);
  //   }
  // }, firebase.firestore());
  // listener = query
  //   ? query?.reduce((acc, curr) => (curr.length ? acc.where(...curr) : acc), listener)
  //   : listener;
  // console.log("🔥 Subscribing to ", path, listener);
  // return listener.orderBy("createdOn", "desc").onSnapshot(async (snap: any) => {
  //   if (path.split(".").length % 2 === 0) {
  //     const data = await snap.data();
  //     store.dispatch(action(data));
  //   } else {
  //     const data = await snap.docs.map((doc: firebase.firestore.QueryDocumentSnapshot) =>
  //       doc.data()
  //     );
  //     store.dispatch(action(data));
  //   }
  // });
};

export const initializeFirestoreListeners = ({ uid }: { uid: string }) => {
  // Setup Base Listeners here
  const db = getFirestore();

  const userListener = onSnapshot(doc(db, "users", uid), async (snap) => {
    const user = (await snap.data()) as User;
    store.dispatch(setUser(user));
  });

  return { userListener };
};

export const firebaseSignupOptions = [
  FacebookAuthProvider.PROVIDER_ID,
  // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
  // firebase.auth.GithubAuthProvider.PROVIDER_ID,
  GoogleAuthProvider.PROVIDER_ID,
  EmailAuthProvider.PROVIDER_ID,
];

export const partialUiConfig = {
  signInOptions: firebaseSignupOptions,
  tosUrl: `${config.app.base_url}/tos`,
  signinFlow: "popup",
  privacyPolicyUrl: function () {
    window.location.assign(`${config.app.base_url}/privacy`);
  },
};
