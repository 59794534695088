import { createAsyncThunk } from "@reduxjs/toolkit";

import { getDoc, doc, getFirestore, writeBatch, updateDoc } from "firebase/firestore";
import { User as FirebaseUser } from "firebase/auth";
import { signOut, getAuth } from "firebase/auth";

import { User } from "types";

export const signUp = createAsyncThunk(
  "user/api/signup",
  async ({ user }: { user: FirebaseUser }, { dispatch }) => {
    const db = getFirestore();
    const batch = await writeBatch(db);

    const userRef = doc(db, "users", user?.uid);

    await batch.set(
      userRef,
      {
        uid: user?.uid,
        email: user?.email,
        createdOn: new Date().toISOString(),
      },
      { merge: true }
    );

    await batch.commit();

    dispatch(getUser({ uid: user.uid }));
  }
);
export const updatePersonalDetails = createAsyncThunk(
  "user/api/updatePersonalDetails",
  async (
    {
      uid,
      personalDetails,
    }: {
      uid: string;
      personalDetails: Partial<User["personalDetails"]>;
    },
    { dispatch }
  ) => {
    const db = getFirestore();
    await updateDoc(doc(db, "users", uid), {
      personalDetails: { ...personalDetails },
    });
    const user = await getDoc(doc(db, "users", uid));
    return (await user.data()) as User;
  }
);
export const getUser = createAsyncThunk("user/api/getUser", async ({ uid }: { uid: string }) => {
  const db = getFirestore();
  const user = await getDoc(doc(db, "users", uid));

  return (await user.data()) as User;
});
export const logout = createAsyncThunk("user/api/logout", async () => {
  const auth = getAuth();
  return await signOut(auth);
});
