import { RefObject, useEffect, useState } from "react";

export const getDaysArray = function (start: Date, end: Date) {
  const output: Date[] = [];
  for (const arr = output, dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return output;
};

export const colors = [
  "#55efc4",
  "#2ecc71",
  "#3498db",
  "#8e44ad",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#1abc9c",
  "#2980b9",
  "#9b59b6",
  "#f1c40f",
  "#95a5a6",
  "#ecf0f1",
  "#2c3e50",
  "#e67e22",
  "#7f8c8d",
  "#e74c3c",
  "#d35400",
  "#c0392b",
  "#f39c12",
  "#bdc3c7",
];

export const avatarColors = ["#2ecc71", "#3498db", "#9b59b6", "#f1c40f", "#e74c3c"];

export { useAppDispatch as useDispatch, useAppSelector as useSelector } from "./redux";

export const writeToLocalStorage = (key: string, values: string[]) => {
  window.localStorage.setItem(key, JSON.stringify(values));
};

export const readFromLocalStorage = (key: string): string[] => {
  const vals = window.localStorage.getItem(key);
  if (vals) {
    return JSON.parse(vals);
  } else {
    return [];
  }
};

export function useOnScreen(ref: RefObject<HTMLDivElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return isIntersecting;
}
