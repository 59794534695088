import { configureStore, Middleware } from "@reduxjs/toolkit";

import userReducer from "../views/App/User/reducer";
import appNotificationsReducer, { setNotification } from "../views/AppNotifications/reducer";
import contentReducer from "../views/App/Content/reducer";
import layoutsReducer from "../views/App/Layouts/reducer";
import overviewReducer from "../views/App/Overview/reducer";

import { AppNotification } from "types";

const customMiddleware: Middleware<{}> = (store) => (next) => (action) => {
  const isApiRejection = action.type.includes("rejected");
  if (isApiRejection) {
    store.dispatch(
      setNotification({
        id: action.type,
        title: "Error",
        type: "danger",
        body: action.error.message,
        autoClose: false,
      } as AppNotification)
    );
  }

  const res = next(action);

  return res;
};

export const store = configureStore({
  reducer: {
    user: userReducer,
    appNotifications: appNotificationsReducer,
    content: contentReducer,
    layouts: layoutsReducer,
    overview: overviewReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(customMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type RootStore = typeof store;
