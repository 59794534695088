import { createAsyncThunk } from "@reduxjs/toolkit";

import { Content } from "types";

// import firebase from "firebase/app";
// import "firebase/functions";
// import "firebase/firestore";

import { getDocs, collection, getFirestore, setDoc, doc, deleteDoc } from "firebase/firestore";

const maxInt = 2147483647; // max 32-bit signed int
function randomInt() {
  return Math.floor(Math.random() * maxInt) + 1;
}

export const getContent = createAsyncThunk(
  "content/api/getContent",
  async ({ uid }: { uid: string }) => {
    const db = getFirestore();
    const content = await getDocs(collection(db, "users", uid, "content"));

    return content.docs.map((doc) => doc.data() as Content);
  }
);

export const addContent = createAsyncThunk(
  "content/api/addContent",
  async ({ uid, content }: { uid: string; content: Content }) => {
    // const ref = await firebase.firestore().collection("users").doc(uid).collection("content").doc();

    const db = getFirestore();
    const ref = doc(collection(db, "users", uid, "content"));
    const random = randomInt();

    const obj = {
      ...content,
      id: ref.id,
      random,
    };

    await setDoc(ref, obj);
    return obj;
  }
);

export const deleteContent = createAsyncThunk(
  "content/api/deleteContent",
  async ({ uid, contentId }: { uid: string; contentId: string }) => {
    const db = getFirestore();
    const ref = doc(db, "users", uid, "content", contentId);
    await deleteDoc(ref);
    return contentId;
  }
);
