import React, { FC, LazyExoticComponent, ReactElement } from "react";
import { lazy } from "react";
import { Layers, Globe, List, User as UserIcon } from "react-feather";

const Home = lazy(async () => await import("../views/Home"));
const TOS = lazy(async () => await import("../views/Home/tos"));
const Privacy = lazy(async () => await import("../views/Home/privacy"));

const App = lazy(async () => await import("../views/App"));
const Auth = lazy(async () => await import("../views/Auth"));

const Overview = lazy(async () => await import("../views/App/Overview"));
const Content = lazy(async () => await import("../views/App/Content"));
const Layouts = lazy(async () => await import("../views/App/Layouts"));
const User = lazy(async () => await import("../views/App/User"));

export const slugPrefixes = {
  overview: "overview",
  content: "content",
  layouts: "layouts",
  user: "user",
};

export interface AppRouteProps {
  private?: boolean;
  path: string;
  url: string;
  label: string;
  component?: LazyExoticComponent<FC<{}>>;
  icon?: ReactElement;
  exact?: boolean;
  divider?: boolean;
  hideFromNav?: boolean;
}

export const routes: AppRouteProps[] = [
  {
    url: "/",
    private: false,
    path: "/",
    label: "Home",
    component: Home,
    exact: true,
  },
  {
    url: "/app/",
    private: true,
    path: "/app/",
    label: "app",
    component: App,
  },
  {
    url: "/auth/",
    private: false,
    path: "/auth/",
    label: "Auth",
    component: Auth,
    exact: true,
  },
  {
    url: "/tos",
    private: false,
    path: "/tos",
    label: "Terms of Service",
    component: TOS,
    exact: true,
  },
  {
    url: "/privacy",
    private: false,
    path: "/privacy",
    label: "Privacy Policy",
    component: Privacy,
    exact: true,
  },
];

export const appRoutes: AppRouteProps[] = [
  {
    url: `/app/${slugPrefixes.overview}`,
    private: true,
    path: `/app/${slugPrefixes.overview}`,
    label: "Overview",
    icon: <Globe size={18} />,
    component: Overview,
    exact: true,
  },
  {
    url: `/app/${slugPrefixes.content}`,
    private: true,
    path: `/app/${slugPrefixes.content}/:contentId?`,
    label: "Content",
    icon: <List size={18} />,
    component: Content,
    exact: true,
  },
  {
    url: `/app/${slugPrefixes.layouts}`,
    private: true,
    path: `/app/${slugPrefixes.layouts}/:layoutId?`,
    label: "Layouts",
    icon: <Layers size={18} />,
    component: Layouts,
    exact: true,
  },
  {
    url: `/app/${slugPrefixes.user}`,
    private: true,
    path: `/app/${slugPrefixes.user}`,
    label: "Profile",
    icon: <UserIcon size={18} />,
    component: User,
    exact: true,
  },
];
