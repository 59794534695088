import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startOfMonth, endOfMonth } from "date-fns";
interface OverviewState {
  startDate: string;
  endDate: string;
  loading: boolean;
}

const today = new Date();
const initialState: OverviewState = {
  startDate: startOfMonth(today).toISOString(),
  endDate: endOfMonth(today).toISOString(),
  loading: false,
};

export const overviewSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload;
    },
  },
});

export const { setLoading, setStartDate, setEndDate } = overviewSlice.actions;
export default overviewSlice.reducer;
