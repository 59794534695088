import { createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { User } from "types";

import { logout, signUp, getUser, updatePersonalDetails } from "./api";

interface UserState {
  user?: User;
  loading: boolean;
  error?: SerializedError;
}

const initialState: UserState = {
  loading: true,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setUser: (state, action: PayloadAction<User>) => {
      state.user = { ...state.user, ...action.payload };
    },
    clearUser: (state) => {
      state.user = undefined;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = { ...state.user, ...action.payload };
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(signUp.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(signUp.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.error = action.error;
      state.loading = false;
    });

    builder.addCase(logout.pending, (state, action) => {
      // state.loading = true;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state = initialState;
      // state.loading = false;
    });
    builder.addCase(updatePersonalDetails.fulfilled, (state, action) => {
      state.user = { ...state.user, ...action.payload };
    });
  },
});

export const { setLoading, setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
