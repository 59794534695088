import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppNotification } from "types";

interface AppNotificationsState {
  current: AppNotification[];
}

const initialState: AppNotificationsState = { current: [] };

export const appNotificationsSlice = createSlice({
  name: "appNotifications",
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<AppNotification>) => {
      const idExists = (state?.current || []).findIndex((f) => f.id === action.payload?.id) > -1;
      if (!idExists) {
        state.current.push(action.payload);
      }
    },
    clearNotification: (state, action: PayloadAction<{ id: string }>) => {
      state.current = (state.current || []).filter((f) => f.id !== action.payload.id);
    },
    clearAllNotifications: (state) => {
      state.current = [];
    },
  },
});

export const { setNotification, clearNotification, clearAllNotifications } =
  appNotificationsSlice.actions;
export default appNotificationsSlice.reducer;
