import React from "react";
import { Route, Redirect } from "react-router-dom";
import { LoadingView } from "../../App";

import { useSelector } from "../../utils";

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const user = useSelector((state) => state?.user);

  if (user?.loading) {
    return <LoadingView />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        user?.user?.uid ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;
