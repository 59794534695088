import React, { ReactElement, FC, useState, useEffect, useCallback } from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "../../utils";

import { getNotifications } from "./selectors";

import { X, XCircle, CheckCircle, AlertCircle } from "react-feather";
import { AppNotification, AppNotificationType } from "types";
import { clearNotification } from "./reducer";

const iconMap: { [key in AppNotificationType]: ReactElement } = {
  info: <AlertCircle className="bg-white me-2" size={24} />,
  success: <CheckCircle className="bg-white me-2" size={24} />,
  danger: <XCircle className="bg-white me-2" size={24} />,
};

const AppNotificationToast: FC<AppNotification> = ({ type, id, body, title, autoClose }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClearNotification = useCallback(() => {
    setShow(false);
    setTimeout(() => {
      dispatch(clearNotification({ id: id }));
    }, 1000);
  }, [id, dispatch]);

  useEffect(() => {
    let timer: any;
    setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    let timer: any;
    if (autoClose) {
      timer = setTimeout(() => {
        handleClearNotification();
      }, 4000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [autoClose, handleClearNotification]);

  const toastClassname = classnames("mb-3 border", styles.toast, {
    [styles.show]: show,
    "border-info": type === "info",
    "border-success": type === "success",
    "border-danger": type === "danger",
  });

  const headerClassname = classnames(styles.header, "border-bottom", {
    [styles.info]: type === "info",
    [styles.success]: type === "success",
    [styles.danger]: type === "danger",
    "border-info": type === "info",
    "border-success": type === "success",
    "border-danger": type === "danger",
  });

  return (
    <div className={toastClassname}>
      <div className={headerClassname}>
        {iconMap[type]}
        <strong className="me-auto bg-white">{title}</strong>
        <button
          onClick={handleClearNotification}
          className={`btn btn-${type} btn-sm text-white d-flex align-items-center justify-content-center`}
        >
          <X className="p-0 m-0 me-2" size={18} />
          Close
        </button>
      </div>
      {body && (
        <div className="p-3 text-muted">
          <small>{body}</small>
        </div>
      )}
    </div>
  );
};

const AppNotifications = () => {
  const wrapperClassname = classnames(styles.wrapper);
  const notifications = useSelector(getNotifications);

  return (
    <div className={wrapperClassname}>
      {(notifications || []).map((notification, index) => (
        <AppNotificationToast
          key={index}
          type={notification.type}
          title={notification.title}
          body={notification.body}
          id={notification.id}
          autoClose={notification.autoClose}
        />
      ))}
    </div>
  );
};

export default AppNotifications;
